import React, { useState, useEffect, useContext } from "react"

/* Import Global Contexts */
import UuidContext from "~context/uuid"
import FirebaseContext from "~context/firebase"
import AuthContext from "~context/auth"

const AuthContextProvider = ({ children }) => {
  const [, { getNewUuid }] = useContext(UuidContext)
  const firebase = useContext(FirebaseContext)

  const [firebaseUser, setFirebaseUser] = useState({})

  useEffect(() => {
    if (firebase) {
      firebase.auth().onAuthStateChanged((user, event) => {
        if (user) {
          setFirebaseUser(user)
        } else {
          setFirebaseUser({})
          // getNewUuid()
        }
      })
    }
  }, [firebase, getNewUuid])

  return (
    <AuthContext.Provider value={firebaseUser}>{children}</AuthContext.Provider>
  )
}

export default AuthContextProvider
