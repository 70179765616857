import React, { useState, useEffect, useContext } from "react"
import firebase from "firebase/app"

// Import Global Context(s)
import FirebaseContext from "~context/firebase"
import AuthContext from "~context/auth"
import UuidContex from "~context/uuid"
import UserContext from "~context/user"

const UserContextProvider = ({ children }) => {
  const firebaseInstance = useContext(FirebaseContext)
  const firebaseUser = useContext(AuthContext)
  const [uuid] = useContext(UuidContex)
  const [firestore, setFirestore] = useState()
  const [token, setToken] = useState()
  const [userDocReference, setUserDocReference] = useState()
  const [user, setUser] = useState()

  // fetch or create firestore user document
  useEffect(() => {
    if (Object.keys(firebaseUser).length && firestore) {
      firestore
        .collection("users")
        .doc(uuid)
        .get()
        .then(doc => {
          if (!doc.exists) {
            firestore
              .collection("users")
              .doc(uuid)
              .set({
                research: {
                  posts: [],
                  quotes: [],
                  reports: [],
                  authors: [],
                  series: [],
                },
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            setUser({ ...doc.data(), hasPendingWrites: false })
          }
        })
        .catch(error => {
          console.log("error.code", error.code)
          console.log("error.message", error.message)
        })
    }
  }, [uuid, firebaseUser, firestore])

  // Get Custom Token from Firebase when UUID is set
  useEffect(() => {
    if (uuid) {
      fetch("/do-not-research-api/auth", {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uuid }),
      })
        .then(res => res.json())
        .then(token => setToken(token))
    }
  }, [uuid])

  // When Custom Token is received from Firebase, attempt to Log In to Firebase
  useEffect(() => {
    if (token && firebaseInstance) {
      firebaseInstance
        .auth()
        .signInWithCustomToken(token)
        .catch(error => {
          console.log(error)
        })
    }
  }, [token, firebaseInstance])

  useEffect(() => {
    if (uuid && firestore) {
      setUserDocReference(firestore.collection("users").doc(uuid))
    }
  }, [uuid, firestore])

  useEffect(() => {
    if (userDocReference) {
      // set listener on reference
      userDocReference.onSnapshot({ includeMetadataChanges: true }, doc => {
        // extender user state
        setUser({
          ...doc.data(),
          hasPendingWrites: doc.metadata.hasPendingWrites,
        })
      })
    }
  }, [userDocReference])

  // create firebase database instance
  useEffect(() => {
    if (firebaseInstance) {
      const db = firebaseInstance.firestore()
      setFirestore(db)
    }
  }, [firebaseInstance])

  // Sign Out Current User
  const signOut = () => {
    firebaseInstance
      .auth()
      .signOut()
      .catch(function (error) {
        console.log(error)
      })
  }

  // generic save and remove functions
  const saveValue = (valueToSave, key) => {
    if (firestore && userDocReference) {
      userDocReference.get().then(doc => {
        userDocReference.update({
          research: {
            ...doc.data().research,
            [key]: [...doc.data().research[key], valueToSave],
          },
        })
      })
    }
  }

  const removeValue = (valueToRemove, key) => {
    if (firestore && userDocReference) {
      userDocReference.get().then(doc => {
        userDocReference.update({
          research: {
            ...doc.data().research,
            [key]: doc.data().research[key].filter(item => item.id !== valueToRemove.id)
          },
        })
      })
    }
  }

  // post functions
  const savePost = postToSave => {
    saveValue(postToSave, "posts")
  }
  const removePost = postToRemove => {
    removeValue(postToRemove, "posts")
  }

  // author functions
  const saveAuthor = authorToSave => {
    saveValue(authorToSave, "authors")
  }
  const removeAuthor = authorToRemove => {
    removeValue(authorToRemove, "authors")
  }

  // series functions
  const saveSeries = seriesToSave => {
    saveValue(seriesToSave, "series")
  }
  const removeSeries = seriesToRemove => {
    removeValue(seriesToRemove, "series")
  }

  // report functions
  const saveReport = reportToSave => {
    saveValue(reportToSave, "reports")
  }
  const removeReport = reportToRemove => {
    removeValue(reportToRemove, "reports")
  }

  const userFunctions = {
    savePost,
    removePost,
    saveAuthor,
    removeAuthor,
    saveSeries,
    removeSeries,
    saveReport,
    removeReport,
    signOut,
  }

  return (
    <UserContext.Provider value={[user, userFunctions]}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
