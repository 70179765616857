import React, { useState, useEffect } from "react"

/* Import Global Contexts */
import LogContext from "~context/log"

const LogContextProvider = ({ element, children }) => {
  const [log, setLog] = useState([])

  const clearLog = () => {
    setLog([])
  }

  const addToLog = logItem => {
    setLog(previousLog => [...previousLog, logItem])
  }

  useEffect(() => {
    if (element) {
      addToLog(`Navigating to Pathname ${element.props.location.pathname}`)
      addToLog(`Loading staticQueryHashes:`)
      element.props.pageResources.page.staticQueryHashes.forEach(
        staticQueryHash => {
          addToLog(`staticQueryHash: ${staticQueryHash}`)
        }
      )
    }
  }, [element])

  return (
    <LogContext.Provider value={[log, addToLog, clearLog]}>
      {children}
    </LogContext.Provider>
  )
}

export default LogContextProvider
