import React, { useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import store from "store2"

// Import Global Context(s)
import UuidContext from "~context/uuid"

const UuidContextProvider = ({ children }) => {
  const [uuid, setUuid] = useState()

  useEffect(() => {
    const uuid = store("uuid")
    if (uuid) {
      setUuid(uuid)
    } else {
      setUuid(uuidv4())
    }
  }, [])

  useEffect(() => {
    if (uuid) {
      store("uuid", uuid)
    }
  }, [uuid])

  const getNewUuid = () => {
    setUuid(uuidv4())
  }

  const functions = {
    getNewUuid,
  }

  return (
    <UuidContext.Provider value={[uuid, functions]}>
      {children}
    </UuidContext.Provider>
  )
}

export default UuidContextProvider
