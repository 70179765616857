import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import slug from "slug"

/* Import Global Context */
import UserContext from "~context/user"

/* Import Global Components */
import Link from "~components/link"

/* Import Local Styles */
import "./header.scss"

const query = graphql`
  query DisplayHighlightsLinkQuery {
    allSanityHighlights(filter: { title: { eq: "Highlights" } }) {
      nodes {
        title
        displayOnWebsite
      }
    }
  }
`

const Header = () => {
  const {
    allSanityHighlights: { nodes: highlights },
  } = useStaticQuery(query)
  const displaySanityHighlights =
    highlights[0] && highlights[0].displayOnWebsite
  const [user] = useContext(UserContext)

  return (
    <header>
      <ul>
        {/* <li>
          <Link to="/">[DNR]</Link>
        </li> */}
        <li>
          <Link to="/">Posts</Link>
        </li>
        {displaySanityHighlights && (
          <li>
            <Link to={`/${slug(highlights[0].title)}`}>
              {highlights[0].title}
            </Link>
          </li>
        )}
        {/* <li>
          <Link to="/reports">Reports</Link>
        </li> */}
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="https://store.donotresearch.net" target="_blank">
            Store
          </Link>
        </li>
      </ul>
      {user ? (
        <Link to="/user">User {user.hasPendingWrites ? "⦾" : "⦿"}</Link>
      ) : null}
    </header>
  )
}

export default Header
