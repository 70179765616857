import React, { Fragment } from "react"

/* Import Global Components */
import Header from "~components/header"

/* Import Global Components */
import LoadingOverlay from "~components/loadingOverlay"
import TransitionRouter from "~components/transitionRouter"

/* Fonts */
import "~assets/fonts/IBMPlexMono-Regular/IBMPlexMono-Regular.css"
import "~assets/fonts/ManifontGroteskBook-webfonts/ManifontGroteskBook-webfont.css"

/* Import Global Normalize */
import "normalize.css"

/* Import Global Reset */
import "~styles/reset.scss"

/* Import Global CSS Variables */
import "~styles/variables.scss"

/* Import Global Styles */
import "~styles/app.scss"

/* Import Local Styles */
import "./layouts.scss"

const Layout = ({ children, location }) => {
  return (
    <Fragment>
      <LoadingOverlay location={location} />
      <Header />
      <TransitionRouter location={location}>{children}</TransitionRouter>
    </Fragment>
  )
}

export default Layout
