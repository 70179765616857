/* https://invertase.io/blog/firebase-with-gatsby */

import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"

// Firebase web config
const config = {
  apiKey: "AIzaSyDFdokv_6dusD48GgN6e7YqrKw-r0mbqtw",
  authDomain: "do-not-research.firebaseapp.com",
  projectId: "do-not-research",
  storageBucket: "do-not-research.appspot.com",
  messagingSenderId: "414657295339",
  appId: "1:414657295339:web:3187ae2906650d50fb1fae",
  measurementId: "G-HRY9N3NSFD",
}

let instance = null

export default function getFirebase() {
  if (typeof window !== "undefined") {
    if (instance) return instance
    instance = firebase.initializeApp(config)
    return instance
  }

  return null
}
