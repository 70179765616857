import React, { useState, useEffect, useContext, useRef } from "react"
import slug from "slug"
import classnames from "classnames"

/* Import Global Contexts */
import LogContext from "~context/log"
import UuidContext from "~context/uuid"

/* Import Global Components */
import FadeInChildElements from "~components/fadeInChildElements"

/* Import Local Style(s) */
import "./loading-overlay.scss"

const LoadingOverlay = ({ location }) => {
  const [uuid] = useContext(UuidContext)
  const [log, , clearLog] = useContext(LogContext)
  const locationRef = useRef(location.pathname)
  const [isInitialLoad, setInitialLoad] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (locationRef.current && locationRef.current !== location.pathname) {
      locationRef.current = location.pathname
      setIsLoaded(false)
    }
  }, [location])

  useEffect(() => {
    if (!isLoaded) {
      if (window) {
        window.setTimeout(() => {
          if (isInitialLoad) {
            setInitialLoad(false)
          }
          setIsLoaded(true)
          clearLog()
        }, 2000)
      }
    }
  }, [isLoaded, setIsLoaded, isInitialLoad, clearLog])

  return (
    <div
      className={classnames("loading-overlay", {
        loaded: isLoaded,
      })}
    >
      <FadeInChildElements>
        {isInitialLoad && uuid && <p>Welcome {uuid}</p>}
        {log && log.map((logItem, index) => {
          return <p key={slug(`${logItem}-${index}`)}>{logItem}</p>})}
      </FadeInChildElements>
    </div>
  )
}

export default LoadingOverlay
