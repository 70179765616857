import React from "react"
import { Link as GatsbyLink } from "gatsby"
import classnames from "classnames"

/* Import Local Styles */
import "./link.scss"

const Link = ({ to, children, className = "" }) => (
  <GatsbyLink className={classnames("link", className)} to={to}>
    <span>{children}</span>
  </GatsbyLink>
)

export default Link
