import React from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"

import "./transition-router.scss"

const TransitionRouter = ({ location, children }) => (
  <TransitionGroup className="transition-group">
    <CSSTransition key={location.key} classNames="transition" timeout={1}>
      {/* the only difference between a router animation and
                any other animation is that you have to pass the
                location to the router so the old screen renders
                the "old location" */}
      <div className="page-wrapper">{children}</div>
    </CSSTransition>
  </TransitionGroup>
)

export default TransitionRouter
