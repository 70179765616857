import React from "react"

/* Import Global Providers */
import UuidContextProvider from "~providers/uuid"
import FirebaseContextProvider from "~providers/firebase"
import AuthProvider from "~providers/auth"
import LogContextProvider from "~providers/log"
import UserContextProvider from "~providers/user"

export const wrapPageElement = ({ element }) => {
  return (
    <UuidContextProvider>
      <FirebaseContextProvider>
        <AuthProvider>
          <UserContextProvider>
            <LogContextProvider element={element}>{element}</LogContextProvider>
          </UserContextProvider>
        </AuthProvider>
      </FirebaseContextProvider>
    </UuidContextProvider>
  )
}
