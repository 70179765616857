/* Based on: https://github.com/gkaemmer/react-fade-in/blob/master/src/FadeIn.tsx*/

/* 
    This component is used like so: 
        <FadeIn>
            <p>Component</p>
            <div>Component</div>
        </FadeIn>

    It will apply styles to each child of FadeIn in order to 'fade them' in, and can use the 
    `delay` and `transitionDuration` props to control the timing.
*/

import {
  useState,
  useEffect,
  isValidElement,
  cloneElement,
  Children,
} from "react"

const FadeIn = ({ children, delay = 250, transitionDuration = 0 }) => {
  const [maxIsVisible, setMaxIsVisible] = useState(0)

  useEffect(() => {
    let count = Children.count(children)

    if (count === maxIsVisible) {
      return () => clearTimeout(timeout)
    }

    // Move maxIsVisible toward count
    const increment = count > maxIsVisible ? 1 : -1
    const timeout = setTimeout(() => {
      setMaxIsVisible(maxIsVisible + increment)
    }, delay)
    return () => clearTimeout(timeout)
  }, [children, delay, maxIsVisible, transitionDuration])

  return Children.map(children, (child, i) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        style: {
          transition: `opacity ${transitionDuration}ms`,
          opacity: maxIsVisible > i ? 1 : 0,
        },
      })
    }
  })
}

export default FadeIn
